import React from "react";
import { Select } from "../../../helpers";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import {
  getEstimateStatusDropdown,
  getInvoiceStatusDropdown,
  getOpportunityStatusDropdown,
  getPresentationStatusDropdown,
  getSalesOrderStatusDropdown,
} from "../../../../selectors/dropdowns";
import { FilterMainComponentProps } from "../Filter";
import { uniqBy } from "lodash";

const selectorMap = {
  "SALES ORDER": getSalesOrderStatusDropdown,
  OPPORTUNITY: getOpportunityStatusDropdown,
  PRESENTATION: getPresentationStatusDropdown,
  INVOICE: getInvoiceStatusDropdown,
  ESTIMATE: getEstimateStatusDropdown,
  "": getOpportunityStatusDropdown,
};

const defaultOptions = [
  { value: "", label: "All" },
  { value: "OPEN", label: "Open" },
  { value: "CLOSED", label: "Closed" },
];

const OrderStatusSelect = ({
  values,
  onChange,
  dependsOnValues = [],
  placeholder = "All",
}: FilterMainComponentProps) => {
  const form_type = dependsOnValues[0] || "";
  let orderStatusOptions = defaultOptions;
  const selector = selectorMap[form_type];
  const options = useSelector(selector) as Array<{ status_id: string; status_name: string }>;

  if (form_type && options) {
    if ("SALES ORDER" === form_type) {
      options.unshift({ status_id: "OPEN", status_name: "Open" });
    }
    // @ts-ignore
    orderStatusOptions = createOptions(options, "status_id", "status_name", {
      addAll: true,
    });
  }

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      // @ts-ignore
      options={uniqBy(orderStatusOptions, 'value')}
      placeholder={placeholder}
    />
  );
};

export default OrderStatusSelect;
